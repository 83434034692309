import React from 'react'

import Layout from '../components/layout'
import { H1, H4, P } from '../components/typography'
import SEO from '../components/seo'
import { ImagePartyGirl } from '../components/image'
import { ImageConcertBackground } from '../components/image2'
import { ImageCardboardApp } from '../components/image3'
import LinkToAppStore from '../images/app-comingsoon.svg'

const IndexPage = () => (
  <Layout>
    <SEO title="Datenschutzrichtlinie"/>
    <section className="section" style={{ paddingTop: `7rem` }}>
      <div className="container">
        <div className="content-body">
          <H1>
            Datenschutzerklärung
          </H1>
          <P>
            Die vorliegende Datenschutzerklärung klärt Sie über die Art, den
            Umfang und den Zweck der Erhebung und Verwendung personenbezogener
            Daten auf unserer Website <a href="/">www.liveonstage360.ch</a> (im
            Folgenden
            „Website“) durch die interpunkt.ag (im Folgenden „wir“ oder „uns“)
            auf und gibt über die Ihnen zustehenden Rechte Auskunft. Diese
            Rechte richten sich nach den anwendbaren Datenschutzgesetzen.
          </P>
          <div>
            <H4>2 Verantwortlicher für die Datenbearbeitung</H4>
            <P>
              Verantwortlicher für die Datenbearbeitung auf dieser Website und
              Ansprechpartner für Datenschutzanliegen ist:
              <p>
                interpunkt.ag <br/>
                Web & Werbeagentur <br/>
                Metzgergasse 6b <br/>
                5034 Suhr <br/>
                <a href="tel:+41 62 531 32 32">+41 62 531 32 32</a> <br/>
                <a href="mailto:info@interpunkt.ch">info@interpunkt.ch</a>
              </p>
            </P>
          </div>
          <div>
            <H4>3 Datenerfassung auf dieser Website</H4>
            <H4>3.1 Logfiles</H4>
            <P>
              <p>
                Unsere Website wird von NetZone AG (Panoramaweg 2, 5040
                Schöftland) gehostet.
                Zur Optimierung und Aufrechterhaltung unserer Website
                protokollieren wir technische Fehler, die beim Aufrufen unserer
                Website allenfalls auftreten. Ferner werden bei der Nutzung
                dieser
                Website automatisch Informationen erhoben, die der Browser Ihres
                Endgeräts an unseren Host-Provider übermittelt. Dies sind:
                IP-Adresse und Betriebssystem Ihres Endgeräts,
                Browsertyp, Version, Sprache
                Datum und Uhrzeit der Serveranfrage,
                aufgerufene Datei,
                die Website, von der aus der Zugriff erfolgte (Referrer URL),
                den Status-Code (z.B. 404) und
                das verwendete Übertragungsprotokoll (z.B. HTTP/2).
              </p>
              <p>
                Diese Daten werden von unserem Host-Provider erhoben und
                gespeichert, um Prozesse und Abläufe insbesondere in
                Zusammenhang
                mit der Nutzung unserer Website und der Sicherheit und
                Stabilität
                des Computersystems optimieren zu können.
              </p>
              <p>
                Weitere Informationen finden Sie in der Datenschutzerklärung der
                NetZone AG unter
                <a
                  href="https://www.netzone.ch/downloads/datenschutzerklaerung_netzone.pdf."
                  target="_blank">
                  https://www.netzone.ch/downloads/datenschutzerklaerung_netzone.pdf.
                </a>
              </p>
            </P>
            <H4>3.2 Kontaktformular</H4>
            <P>
              <p>
                Wenn Sie unser Kontaktformular benutzen, werden Ihre Angaben aus
                dem Anfrageformular zur Bearbeitung der Anfrage und für den Fall
                von Anschlussfragen von uns bearbeitet. In der Regel benötigen
                wir
                folgende Angaben:
                Vor- und Nachname
                E-Mail-Adresse
                Nachrichteninhalt
              </p>
            </P>
            <H4>3.3 Cookies</H4>
            <P>
              <p>
                Wir setzen auf unserer Website Cookies ein. Cookies sind kleine
                Dateien, die auf Ihrem Endgerät abgelegt werden und die Ihr
                Browser speichert. Einige der von uns verwendeten Cookies werden
                automatisch gelöscht, wenn Sie unsere Website verlassen. Andere
                Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
                löschen oder bis sie ablaufen. Diese Cookies ermöglichen es,
                Ihren
                Browser beim nächsten Besuch unserer Website wiederzuerkennen.
                In Ihrem Browser können Sie einstellen, dass Sie über das Setzen
                von Cookies vorab informiert werden und im Einzelfall
                entscheiden
                können, ob Sie die Annahme von Cookies für bestimmte Fälle oder
                generell ausschliessen, oder dass Cookies komplett verhindert
                werden. Dadurch kann die Funktionalität der Website
                eingeschränkt
                werden.
              </p>
            </P>
          </div>
          <div>
            <H4>4 Externe Dienste</H4>
            <P>Auf unserer Website setzen wir verschiedene Dienste von
              Drittanbietern ein. Nachfolgend legen wir im Einzelnen dar, um
              welche Dienste es sich handelt, wofür wir sie einsetzen und welche
              Daten gesammelt werden.</P>
            <H4>4.1 Google Analytics</H4>
            <P>
              <p>Wir verwenden Google Analytics, um die Nutzung unserer Website
                auszuwerten und Informationen zwecks Verbesserung unserer
                Dienstleistungen zu erhalten.
                Die Analysedienste verwenden Cookies, um Ihre Nutzung der
                Website auszuwerten, um Reports über die Aktivitäten der Nutzer
                auf der Website zu erstellen und um weitere mit der Nutzung der
                Website und Applikationen verbundene Dienstleistungen uns
                gegenüber zu erbringen. Daran haben wir ein berechtigtes
                Interesse. Die Anbieter der Webanalysedienste speichern die über
                Cookies erhobenen Informationen nur in anonymisierter Form und
                bearbeiten sie in aggregierter Form.
              </p>
              <p>
                Google Analytics ist ein Dienst von Google. Wir verwenden diesen
                nur mit aktivierter IP-Anonymisierung. Das bedeutet, dass Ihre
                IP-Adresse in der Schweiz, im Vereinigten Königreich, in der EU
                oder im EWR gekürzt wird. Nur in Ausnahmefällen wird die volle
                IP-Adresse an einen Server von Google in den USA übertragen und
                dort gekürzt. Sie können verhindern, dass Google Analytics Ihre
                Daten verwendet, in dem Sie ein Browser-Add-on zur Deaktivierung
                von Google Analytics herunterladen
                (https://tools.google.com¬/dlpage¬/gaoptout?hl=de) und
                installieren. Weitere Informationen zum Datenschutz und ihren
                Wahlmöglichkeiten im Zusammenhang mit Google Analytics finden
                Sie hier:
                <a
                  href="https://support.google.com/analytics/answer/6004245?hl=de"
                  target="_blank">
                  https://support.google.com/analytics/answer/6004245?hl=de
                </a>.
              </p>
            </P>
            <H4>4.2 Google Tag Manager</H4>
            <P>
              <p>
                Wir setzen auf unserer Website Google Tag Manager ein, um Tags
                für unsere Website und Applikationen zu erstellen. Diese Tags
                ermöglichen uns, Marketingmassnahmen betreffend unsere
                Dienstleistungen gezielt auf potentielle Kunden auszurichten
                (Re-Targeting).
              </p>
              <p>
                Wir verwenden Werbetechnologien von Google (Ads) und Facebook.
                Gemäss unseren Einstellungen in Google Tag Manager setzen Google
                und Facebook sog. Conversion Cookies. Das ist notwendig, um die
                Wirksamkeit der entsprechenden Werbemassnahmen nachzuprüfen.
                Daran haben wir ein berechtigtes Interesse. Zudem verwenden wir
                Google Tag Manager, um Re-Targeting-Tags zu setzen. Diese Tags
                ermöglichen es uns, Nutzer gezielt mit Informationen zu unseren
                Dienstleistungen anzusprechen, wenn diese eine andere Websites
                (z.B. Facebook) besuchen. Informationen hinsichtlich Ihrer
                Wahlmöglichkeiten in Bezug auf nutzungsbasierter Online-Werbung
                finden Sie hier: <a
                href="http://www.youronlinechoices.com/ch-de/"
                target="_blank">http://-www.youronlinechoices.com¬/ch-de/</a>.
                Informationen zu Einstellungsmöglichkeiten hinsichtlich Cookies
                finden sie oben unter Ziffer 3.3.
              </p>
            </P>
          </div>
          <div>
            <H4>5 Links</H4>
            <P>Auf unserer Website finden Sie Links auf Seiten von
              Drittanbietern. Wir sind nicht verantwortlich für die Inhalte und
              Datenschutzvorkehrungen auf externen Websites, welche Sie über die
              Links erreichen können. Bitte informieren Sie sich über den
              Datenschutz direkt auf den entsprechenden Websites.</P>
          </div>
          <div>
            <H4>6 Weitergabe von Daten an Dritte</H4>
            <P>
              <p>
                Damit wir Ihnen die Informationen auf unserer Website anbieten
                können, arbeiten wir mit verschiedenen Dienstleistern zusammen,
                namentlich mit IT-Dienstleistern, um Ihnen eine zeitgemässe
                Website anbieten zu können. Diese verwenden Ihre Daten nur im
                Rahmen der Auftragsabwicklungen für uns.
              </p>
              <p>
                Wir nehmen eine Datenübermittlung an Stellen ausserhalb der
                Schweiz und der Europäischen Union (Drittstaat) ohne Ihre
                Einwilligung nur vor, sofern dies nach dem jeweiligen Vertrag
                erforderlich ist, zur Erfüllung gesetzlicher Verpflichtungen
                oder zur Wahrung unserer berechtigten Interessen.
              </p>
            </P>
          </div>
          <div>
            <H4>7 Ihre Rechte</H4>
            <P>
              <p>
                Sie haben das Recht, Auskunft zu den von uns über Sie
                verarbeiteten Personendaten zu erhalten. Sie haben ein Recht auf
                Berichtigung Ihrer Personendaten sowie auf Einschränkung der
                Verarbeitung. Zudem haben Sie ein Recht auf Löschung Ihrer
                Personendaten und ein Recht, Verarbeitungen von Personendaten zu
                widersprechen. Wenn unsere Verarbeitung auf Ihrer Einwilligung
                beruht, haben Sie ausserdem das Recht, Ihre Einwilligung in die
                Verarbeitung Ihrer Daten jederzeit zu widerrufen. Soweit die
                DSGVO gilt und wir die Verarbeitung zur Erfüllung eines Vertrags
                oder aufgrund Ihrer Einwilligung vornehmen, haben Sie zudem ein
                Recht, eine Kopie der Personendaten zur Übertragung an einen
                Dritten zu erhalten. Eine von Ihnen erteilte Einwilligung können
                Sie mit Wirkung für die Zukunft jederzeit widerrufen. Dazu
                reicht eine formlose Mitteilung per E-Mail an uns.
              </p>
              <p>
                Bitte beachten Sie, dass die vorstehenden Rechte gesetzlichen
                Einschränkungen unterstehen und ggf. die Erbringung unserer
                Dienstleistungen beeinträchtigen oder verunmöglichen können.
                Sie sind berechtigt, Ihre Ansprüche gerichtlich durchzusetzen
                oder eine Beschwerde bei der zuständigen Datenschutzbehörde
                einzureichen. Für die Schweiz ist der Eidgenössische
                Datenschutz- und Öffentlichkeitsbeauftragte zuständig
                (<a href="https://www.edoeb.admin.ch"
                    target="_blank">https://www.edoeb.admin.ch</a>).
              </p>
            </P>
          </div>
          <div>
            <H4>8 Aktualität und Änderung dieser Datenschutzerklärung</H4>
            <P>
              <p>
                Wir können diese Datenschutzerklärung jederzeit ändern oder
                anpassen. Die aktuelle Datenschutzerklärung kann jederzeit auf
                <a href="/">www.liveonstage360.ch</a> abgerufen werden.
              </p>
            </P>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
